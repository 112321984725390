import { IEmailData } from "../../models/sourceJSON.model";

export const generateMailData = (
	userType: string,
	mailMapping: Array<string>,
	mailTypeContent: any
): IEmailData | null => {
	/* belirlenen mailType ile mail content dönen method */
	const mailType = findMailByType(userType, mailMapping);

	if (mailType === null) return null;

	return mailTypeContent[mailType];
};

const findMailByType = (userType: string, mailMapping: Array<string>) => {
	/* puan ile belirlenen userType ile mailType belirleyen method */
	const mapping = mailMapping.find((element) => element.startsWith(userType));

	if (!mapping) {
		return null;
	}

	return mapping.split(":")[1];
};
