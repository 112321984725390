// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import sourceReducer from './sourceSlice';

export const store = configureStore({
  reducer: {
    source: sourceReducer,
  },
});

// RootState tipini tanımlayın
export type RootState = ReturnType<typeof store.getState>;

// Dispatch tipini tanımlayın
export type AppDispatch = typeof store.dispatch;
