export const fetchSurveyDataFromJSON = async () => {
	let surveyData: any = {};

	await fetch("/source.json")
		.then((response) => response.json())
		.then((data) => {
			surveyData = data.surveyData;
		})
		.catch((error) => {
			console.error("Error fetching the JSON:", error);
		});
        
	return surveyData;
};
