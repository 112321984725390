import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../helpers/scroll/scrollToTop";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../store/sourceSlice';
import { AppDispatch, RootState } from '../../store/store';
import "./HomePage.scss";

export default function HomePage() {
	const dispatch = useDispatch<AppDispatch>();
	const source = useSelector((state: RootState) => state.source);

	useEffect(() => {
		if (source.status === 'idle') {
			dispatch(fetchSourceData());
		}
	}, [dispatch, source.status]);

	useEffect(() => {
		scrollToTop();
	}, []);

	const handleTestClick = () => {
		testRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "center",
		});
	};

	const ref = useRef<HTMLDivElement>(null);
	const testRef = useRef<HTMLImageElement>(null);

	if (source.status === 'loading' || source.status === 'idle') {
		return <div>Loading...</div>;
	}

	if (source.status === 'failed' && source.data) {
		return <div>Error loading data.</div>;
	}

	return (
		<div>
			<div className="homePage">
				<div className="heroContainer">
					<img className="bgImage" src={source?.data.homePage.heroContainer.imageURL} />
					<div className="title">{source?.data.homePage.heroContainer.title}</div>
					<div className="description">{source?.data.homePage.heroContainer.description}</div>

					<div className="buttons">
						<Link className="surveyLink" to={'/survey'} onClick={handleTestClick}>
							{source?.data.homePage.heroContainer.buttonLabel}
						</Link>
					</div>
				</div>

				<div ref={ref} className="infoContainer">
					<span className="description">{source?.data.homePage.infoContainer.description3}</span>
					<br />
					<span className="description">{source?.data.homePage.infoContainer.description1}</span>
					<span className="description">{source?.data.homePage.infoContainer.description2}</span>
					<span className="title">{source?.data.homePage.infoContainer.title}</span>
				</div>

				<div className="cardContainer">
					<div className="card">
						<div className="cardBadge">{source?.data.homePage.cardContainer.first.badge}</div>
						<span className="cardText">{source?.data.homePage.cardContainer.first.text}</span>
					</div>

					<div className="card">
						<div className="cardBadge">{source?.data.homePage.cardContainer.second.badge}</div>
						<span className="cardText">{source?.data.homePage.cardContainer.second.text}</span>
					</div>

					<div className="card">
						<div className="cardBadge">{source?.data.homePage.cardContainer.third.badge}</div>
						<span className="cardText">{source?.data.homePage.cardContainer.third.text}</span>
					</div>
				</div>

				<div className="startContainer">
					{/* <img
						src={source?.data.homePage.startContainer.image1URL}
						className="arrowImage"
					/>
					<img
						src={source?.data.homePage.startContainer.image2URL}
						className="secondImage"
						ref={testRef}
					/> */}

					<span className="title">{source?.data.homePage.startContainer.title}</span>
					<span className="description">{source?.data.homePage.startContainer.description}</span>

					<span className="title">{source?.data.homePage.startContainer.title2}</span>
					<span className="description">{source?.data.homePage.startContainer.description2}</span>

					<span className="title">{source?.data.homePage.startContainer.title3}</span>

					<Link to={"/survey"} className="startSurvey">
						{source?.data.homePage.startContainer.buttonLabel}
					</Link>

					<span className="legalText">{source?.data.homePage.startContainer.legalText}</span>
				</div>
			</div>
		</div>

	);
}
