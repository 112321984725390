import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../../store/sourceSlice';
import { AppDispatch, RootState } from '../../../store/store';
import './CourtesyModal.scss';
import { Modal } from 'antd';
import ShareIcon from "../../../assets/ios-share.svg";


interface ConditionsModalProps {
    isOpen: boolean;
    onCancelModal: any;
    title: string;
    isNegative?: boolean;
}


export function CourtesyModal({ isOpen, onCancelModal, title }: ConditionsModalProps) {
    const dispatch = useDispatch<AppDispatch>();
    const source = useSelector((state: RootState) => state.source);
    //console.log(source);

    useEffect(() => {
        if (source.status === 'idle') {
            dispatch(fetchSourceData());
        }
    }, [dispatch, source.status]);

    if (source.status === 'loading' || source.status === 'idle') {
        return <div>Loading...</div>;
    }

    if (source.status === 'failed' && source.data) {
        return <div>Error loading data.</div>;
    }

    const { courtesyModal } = source.data.modals;

    const referAFriend = () => {
        const shareURL = window.location.href + `?utm_source=${courtesyModal.referAFriend.utm_source}`;

        if (navigator.share) {
            navigator
                .share({
                    title: courtesyModal.referAFriend.title,
                    text: courtesyModal.referAFriend.text,
                    url: shareURL,
                })
                .then(() => console.log("Success"))
                .catch((error) => console.log("Error", error));
        } else {
            console.log("navigator.share is not supported.");
        }
    }
    
    return (
        <Modal
            open={isOpen}
            onCancel={onCancelModal}
            title={title}
        >
            <div className="courtesyModal">
                <div className="conditionsModal successModal">
                    <div className="contentContainer successModal">
                        {(
                            <>
                                <img
                                    src={courtesyModal.contentContainer.imageURL}
                                    className="conditionsModal-modalIcon"
                                />
                                <h3>
                                    <b>{courtesyModal.contentContainer.title}</b>
                                </h3>
                                <p>{courtesyModal.contentContainer.description}</p>
                            </>
                        )}
                        <button
                            onClick={referAFriend}
                            className="referAFriendButton"
                        >
                            <img className="shareIcon" src={ShareIcon} />
                            <span className="shareText">{courtesyModal.contentContainer.buttonLabel}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
