import { UserTypeResults } from './survey.model';

export const findElementByName = (surveyJSON: any, targetName: any) => {
	/* name ile JSON'dan ilgili soru objesini bulan method  */
	for (let page of surveyJSON.pages) {
		for (let element of page.elements) {
			if (element.name === targetName) {
				return element;
			}
		}
	}

	return null;
};

export const addSelectedProductToStore = (
	selectedProduct: any,
	selectedProducts: any
) => {
	/* seçilen ürünün diğer ürünlerin arasına eklenmesini sağlayan method */
	const products = {
		...selectedProducts,
		...selectedProduct,
	};

	localStorage.setItem('products', JSON.stringify(products));
	
	return products;
};

export const getProductForChoice = (
	questionElement: any,
	selectedChoice: any
): any => {
	/*  Verilen cevabın hangi ürüne denk geldiğini bulup return eden method */
	let selectedChoiceIndex = questionElement?.choices?.indexOf(selectedChoice);

	if (
		selectedChoiceIndex !== -1 &&
		questionElement.products[selectedChoiceIndex]
	) {
		let productName = questionElement.products[selectedChoiceIndex];
		let result: any = {};
		result[productName] = true;

		return result;
	}

	return {};
};

export const createListFromProducts = (products: any) => {
	if (products && Object.keys(products).length > 0) {
		const list = Object.keys(products).join(';');

		return list;
	} else {
		return [];
	}
};

export const setResultsForChoice = (
	questionElement: any,
	selectedChoice: any,
	typeResults: UserTypeResults
): any => {
	/*  Verilen cevaba göre user type belirleyen method */
	let selectedChoiceIndex = questionElement?.choices?.indexOf(selectedChoice);

	if (
		selectedChoiceIndex !== -1 &&
		questionElement.results[selectedChoiceIndex] !== null &&
		questionElement.results[selectedChoiceIndex] !== undefined &&
		questionElement.results[selectedChoiceIndex].length > 0
	) {
		let resultType = questionElement.results[selectedChoiceIndex];
		const [type, score] = resultType.split(':');

		const userTypeResults = addUserTypePoints(
			typeResults,
			type,
			parseInt(score)
		);

		return userTypeResults;
	}
};

export const addUserTypePoints = (
	results: UserTypeResults, // result objemiz tüm puanların olduğu
	selectedType: string, // o an cevap verilen sorudan gelen type
	score: number // puan
) => {
	const userTypeResults = results || {};

	if (
		userTypeResults !== null &&
		userTypeResults !== undefined &&
		userTypeResults[selectedType] !== undefined
	) {
		userTypeResults[selectedType] = userTypeResults[selectedType] + score;
	} else {
		userTypeResults[selectedType] = score;
	}

	return userTypeResults;
};

export const getUserType = (userTypeResults: UserTypeResults) => {
	let userType = Object.keys(userTypeResults).reduce((a, b) =>
		userTypeResults[a] > userTypeResults[b] ? a : b
	);

	return userType;
};
