export const makeRequest = (endpoint: string, method = "POST", body?: any) => {
	const apiURL = endpoint;

	return new Promise(async (resolve, reject) => {
		const requestOptions = {
			method: method,
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: body ? JSON.stringify(body) : "",
		};
		await fetch(apiURL, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				resolve(data);
			})
			.catch((err) => {
				reject({ success: false });
			})
			.finally(() => {
				localStorage.setItem('products', '');
			})
	});
};
