import { SurveyJS } from "../../components/survey/Survey";
import './SurveyPage.scss';

export default function SurveyPage() {

	return (
		<div className="surveyPage">
			<SurveyJS />
		</div>
	);
}
