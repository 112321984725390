import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Survey } from 'survey-react-ui';
import * as SurveyTheme from "survey-core/themes";
import { Model } from 'survey-core';
import { findElementByName, addSelectedProductToStore, getProductForChoice, setResultsForChoice, getUserType } from "../../helpers/survey/survey";
import { getNextPage, PAGE_NAMES } from "../../helpers/flow/flow";
import { makeRequest } from "../../http";
import './Survey.scss';
import 'survey-core/defaultV2.min.css';
import * as SurveyCore from "survey-core";
import { inputmask } from "surveyjs-widgets";
import { generatePostData } from "../../helpers/http/http";
import { UserTypeResults } from "../../helpers/survey/survey.model";
import { fetchSurveyDataFromJSON } from "../../helpers/data/surveyData";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../store/sourceSlice';
import { AppDispatch, RootState } from '../../store/store';

inputmask(SurveyCore);

export function SurveyJS() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const source = useSelector((state: RootState) => state.source);
    const [surveyData, setSurveyData] = useState<any>(null);
    const [survey, setSurvey] = useState<any>(null);
    const [userTypeResults, setUserTypeResults] = useState<UserTypeResults>({});
    const [ipAddress, setIPAddress] = useState('')
    const flow = new URLSearchParams(window.location.search).get('flow') || '1';

    let selectedProducts: any = null;

    useEffect(() => {
        if (source.status === 'idle') {
            dispatch(fetchSourceData());
        }
    }, [dispatch, source.status]);

    useEffect(() => {
        fetchSurveyDataFromJSON().then(async (sourceData) => {
            const survey = new Model(sourceData);
            survey.applyTheme(SurveyTheme.PlainLight);

            setSurveyData(sourceData);
            setSurvey(survey);

            survey?.onValueChanging.add((survey: any, question: any) => {
                let foundElement = findElementByName(sourceData, question.name);
                let selectedProductObj = getProductForChoice(foundElement, question.value);

                if (foundElement && foundElement.results && foundElement.results.length > 0 && foundElement.choices && foundElement.choices.length > 0) {
                    const newUserTypeResults = setResultsForChoice(foundElement, question.value, userTypeResults);

                    setUserTypeResults(newUserTypeResults);
                }

                const isProductToAdd = Object.keys(selectedProductObj).length > 0;

                if (isProductToAdd) {
                    selectedProducts = addSelectedProductToStore(selectedProductObj, selectedProducts);
                }

            });
        })
    }, []);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIPAddress(data.ip);
            })
            .catch(error => console.log(error))
    }, []);

    const handleNavigationAfterSurvey = () => {
        const nextPage = getNextPage(PAGE_NAMES.SURVEY, flow);

        if (nextPage) {
            navigate(`${nextPage}?flow=${flow}`);
        }
    };

    const onComplete = (survey: any, options: any) => {
        let userType = 'Type0';

        if (Object.keys(userTypeResults).length > 0) {
            userType = getUserType(userTypeResults);
        }

        const mailMapping = source.data.mailContent.mailMapping;
        const mailTypeContent = source.data.mailContent.mailTypeContent;
        const domain = source.data.additionalInformation.domain;
        const list = source.data.additionalInformation.list;
        const campaignID = source.data.additionalInformation.campaignID;

        const resultsObject = {
            ...survey.data,
            selectedProducts,
            userType,
            mailMapping,
            mailTypeContent,
            domain,
            list,
            campaignID,
            ipAddress,
            answers: survey.data
        }

        localStorage.setItem('postData', JSON.stringify(resultsObject));
        localStorage.setItem('userType', JSON.stringify(userType));

        const postData = generatePostData(resultsObject, source.data.surveyData);
        const apiURL = `https://leadmgmthq.com/api/public/integration/${source.data.additionalInformation.publisherDetailUID}`

        makeRequest(apiURL, 'POST', postData);

        handleNavigationAfterSurvey();
    };

    if (source.status === 'loading' || source.status === 'idle') {
        return <div>Loading...</div>;
    }

    if (source.status === 'failed' && source.data) {
        return <div>Error loading data.</div>;
    }

    return (
        <div className="survey" id="form_survey">
            {surveyData && surveyData !== null && surveyData.pages.length > 0 && <Survey model={survey} onComplete={onComplete} />}
        </div>
    )
}
