import { useEffect, useState } from "react"
import { ConditionsModal } from "../modals/conditionsModal/ConditionsModal"
import { PrivacyModal } from "../modals/privacyModal/PrivacyModal"
import "./Footer.scss"
import { scrollToTop } from "../../helpers/scroll/scrollToTop"
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../store/sourceSlice';
import { AppDispatch, RootState } from '../../store/store';

export function Footer() {
	const [conditionsIsOpen, setConditionsIsOpen] = useState(false)
	const [privacyIsOpen, setPrivacyIsOpen] = useState(false)
	const dispatch = useDispatch<AppDispatch>();
	const source = useSelector((state: RootState) => state.source);

	useEffect(() => {
		if (source.status === 'idle') {
			dispatch(fetchSourceData());
		}
	}, [dispatch, source.status]);

	useEffect(() => {
		scrollToTop();
	}, []);

	const openConditionsModal = () => {
		setConditionsIsOpen(true)
	}

	const closeConditionsModal = () => {
		setConditionsIsOpen(false)
	}

	const openPrivacyModal = () => {
		setPrivacyIsOpen(true)
	}

	const closePrivacyModal = () => {
		setPrivacyIsOpen(false)
	}

	if (source.status === 'loading' || source.status === 'idle') {
		return <div>Loading...</div>;
	}

	if (source.status === 'failed' && source.data) {
		return <div>Error loading data.</div>;
	}

	return (
		<footer>
			<div className="modalContainers">
				<ConditionsModal
					isOpen={conditionsIsOpen}
					onCancelModal={closeConditionsModal}
					title={source.data.modals.conditionsModal.title}
				/>
				<PrivacyModal
					isOpen={privacyIsOpen}
					onCancelModal={closePrivacyModal}
					title={source.data.modals.privacyModal.title}
				/>
			</div>
			<div className="logoContainer">
				&nbsp;
			</div>
			<div className="contractsContainer">
				<a href="https://joy-leads.s3.eu-central-1.amazonaws.com/wiw/terms-and-conditions.html" target="blank">Terms and Conditions</a>
				<a href="https://joy-leads.s3.eu-central-1.amazonaws.com/wiw/privacy-policy.html" target="blank">Privacy Policy</a>
				{/* <a onClick={openConditionsModal}>{source.data.footer.contractsContainer.conditionsModalTitle}</a>
				<a onClick={openPrivacyModal}>{source.data.footer.contractsContainer.privacyModalTitle}</a> */}
			</div>
		</footer>
	)
}
