import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../store/sourceSlice';
import { AppDispatch, RootState } from '../../store/store';
import "./404.scss";
import { useEffect } from "react";

export default function Page404() {
    const dispatch = useDispatch<AppDispatch>();
    const source = useSelector((state: RootState) => state.source);

    useEffect(() => {
        if (source.status === 'idle') {
            dispatch(fetchSourceData());
        }
    }, [dispatch, source.status]);

    if (source.status === 'loading' || source.status === 'idle') {
        return <div>Loading...</div>;
    }

    if (source.status === 'failed' && source.data) {
        return <div>Error loading data.</div>;
    }

    return (
        <div className="page404">
            <span className="page404-title">
                {source.data.notFound404Page.title}
            </span>

            <span className="page404-text">
                {source.data.notFound404Page.text}
            </span>

            <Link to={source.data.notFound404Page.redirectHref} className="page404-goHome">
                {source.data.notFound404Page.buttonLabel}
            </Link>
        </div>
    );
}
