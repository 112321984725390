import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from "./App";
import "./index.scss";

const rootElement = document.getElementById("athenaApp");
const root = createRoot(rootElement as HTMLElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);