export enum PAGE_NAMES {
    HOME = '/',
    SURVEY = '/survey',
    RESULT = '/result'
}

const FLOW_CONFIGS: any = {
    1: ['/', '/survey', '/result'],
    2: ['/result', '/', '/survey']
  };

export const getNextPage = (currentPage: any, flow: any) => {
  const flowPages: any = FLOW_CONFIGS[flow]
  const currentIndex = flowPages.indexOf(currentPage)

  if (currentIndex + 1 === flowPages.length) {
    return flowPages[0] // eğer currentIndex son sayfaysa ana sayfaya at. (result page kapatırken home page'e atıcak mesela (1.flow))
  } else {
    return flowPages[currentIndex + 1] // eğer currentIndex son sayfa değilse sıradaki sayfaya at.
  }
}

export const getPrevPage = (currentPage: any, flow: any) => {
  const flowPages = FLOW_CONFIGS[flow]
  const currentIndex = flowPages.indexOf(currentPage)
  return flowPages[currentIndex - 1]
}

