import React, { useEffect } from "react";
import HomePage from "./pages/home/HomePage";
import SurveyPage from "./pages/survey/SurveyPage";
import ResultPage from "./pages/result/ResultPage";
import Page404 from "./pages/404/404";

import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";

import "./App.scss";
import './responsive.scss';

import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { appHeight } from "./helpers/app/appHeight";


export default function App() {
  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => { };
  }, []);

  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}
