import { useState, useEffect } from "react";
import { CourtesyModal } from "../../components/modals/courtesyModal/CourtesyModal";
import { useNavigate } from "react-router-dom";
import { getNextPage, PAGE_NAMES } from "../../helpers/flow/flow";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../store/sourceSlice';
import { AppDispatch, RootState } from '../../store/store';
import './ResultPage.scss';

export default function ResultPage() {
	const dispatch = useDispatch<AppDispatch>();
	const source = useSelector((state: RootState) => state.source);
    const navigate = useNavigate();
	const [isCourtesyModalOpen, setIsCourtesyModalOpen] = useState(true);
    const flow = new URLSearchParams(window.location.search).get('flow') || '1';

	useEffect(() => {
		if (source.status === 'idle') {
			dispatch(fetchSourceData());
		}
	}, [dispatch, source.status]);


	if (source.status === 'loading' || source.status === 'idle') {
		return <div>Loading...</div>;
	}

	if (source.status === 'failed' && source.data) {
		return <div>Error loading data.</div>;
	}

	const onCancelModal = () => {
		const nextPage = getNextPage(PAGE_NAMES.RESULT, flow);
		
		setIsCourtesyModalOpen(false);

		if (nextPage) {
            navigate(`${nextPage}?flow=${flow}`);
        }
	}

	return (
		<div className="resultPage">
			<CourtesyModal isOpen={isCourtesyModalOpen} title={source.data.modals.courtesyModal.title} onCancelModal={onCancelModal} />
		</div>
	);
}
