import { useNavigate } from "react-router-dom";

import './Header.scss';

export function Header() {
	const navigate = useNavigate();

    return (
        <header>
            <div className="logoContainer" onClick={() => navigate('/', { replace: true })}>
                <img src="https://joy-leads.s3.eu-central-1.amazonaws.com/wiw/wiw-logo.png" />
                <span className="logoContainer-title">Wisdom in Wellness</span>
            </div>
        </header>
    )
}
