import { createListFromProducts } from '../survey/survey';
import { generateMailData } from '../data/mailContent';
import { formatDate } from '../data/formatDate';

export const generatePostData = (surveyData: any, allQuestions: any) => {
	const productsFromStore = localStorage.getItem('products') || '';
	let Products;
	let surveyDataObj = {
		...surveyData,
	};
	let answers = {
		...surveyDataObj.answers,
	};

	if (productsFromStore) {
		Products = createListFromProducts(JSON.parse(productsFromStore));
	}

	const Domain = surveyDataObj.domain;
	const List = surveyDataObj.list;
	const CampaignID = surveyDataObj.campaignID;
	const timestamp = new Date().toISOString();
	const signUpIP = surveyDataObj.ipAddress;
	const mailData = generateMailData(
		surveyDataObj.userType,
		surveyDataObj.mailMapping,
		surveyDataObj.mailTypeContent
	);

	let MailTitle = '';
	let MailText1 = '';
	let MailText2 = '';
	let MailText3 = '';
	let MailText4 = '';

	if (mailData !== null) {
		MailTitle = mailData.MailTitle;
		MailText1 = mailData.MailText1;
		MailText2 = mailData.MailText2;
		MailText3 = mailData.MailText3;
		MailText4 = mailData.MailText4;
	}

	let postData: any = {
		List,
		CampaignID,
		Domain,
		// MailTitle,
		// MailText1,
		// MailText2,
		// MailText3,
		// MailText4,
		signUpDT: timestamp,
		signUpIP,
	};

	if (Products) {
		postData = {
			...postData,
			Products,
		};
	}

	allQuestions.pages.forEach((page: any) => {
		page.elements.forEach((element: any) => {
			if (element.addToPost) {
				// element.name ile surveyData içindeki ilgili cevabı bul ve postData'ya ekle
				postData[element.name] = surveyDataObj[element.name];
			}
			if (element.isDate) {
				postData[element.name] = formatDate(surveyDataObj[element.name]);
				answers[element.name] = formatDate(surveyDataObj[element.name]);
				postData.Answers = JSON.stringify(answers);
			}
		});
	});

	return postData;
};
