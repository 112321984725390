import { useEffect, useState } from "react";
import { makeRequest } from "../../../http";
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSourceData } from '../../../store/sourceSlice';
import { AppDispatch, RootState } from '../../../store/store';
import './ConditionsModal.scss';

interface ConditionsModalProps {
    isOpen: boolean;
    onCancelModal: any;
    title?: string;
}

export function ConditionsModal({ isOpen, onCancelModal, title = '' }: ConditionsModalProps) {
    const [conditionsModalData, setConditionsModalData] = useState<any>();

    const dispatch = useDispatch<AppDispatch>();
    const source = useSelector((state: RootState) => state.source);

    useEffect(() => {
        if (source.status === 'idle') {
            dispatch(fetchSourceData());
        }
    }, [dispatch, source.status]);

    useEffect(() => {
        if (source.status === 'succeeded') {
            const apiURL = source.data.modals.privacyModal.apiURL;

            makeRequest(apiURL, 'GET').then((response: any) => {
                setConditionsModalData(response);
            }).catch(() => {
                setConditionsModalData(null);
            })
        }

    }, []);

    if (source.status === 'loading' || source.status === 'idle') {
        return <div>Loading...</div>;
    }

    if (source.status === 'failed' && source.data) {
        return <div>Error loading data.</div>;
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onCancelModal}
            title={title}
        >
            {
                conditionsModalData ?
                    <div className="conditionsModal">
                        <div dangerouslySetInnerHTML={{ __html: conditionsModalData }} />
                    </div>
                    :
                    <h1>
                        {source.data.modals.privacyModal.errorMessage}
                    </h1>
            }
        </Modal>
    )
}
