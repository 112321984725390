import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISourceJSON } from "../models/sourceJSON.model";

interface SourceState {
	data: ISourceJSON;
	status:'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState = {
	data: {},
	status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
} as SourceState;

// Asenkron thunk
export const fetchSourceData = createAsyncThunk<
	ISourceJSON,
	void,
	{ rejectValue: string }
>("source/fetchData", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch("/source.json");
		if (!response.ok) {
			throw new Error("Server error");
		}
		const data: ISourceJSON = await response.json();
		return data;
	} catch (error) {
		return rejectWithValue("Data could not be fetched");
	}
});

const sourceSlice = createSlice({
	name: "source",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSourceData.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchSourceData.fulfilled,
				(state, action: PayloadAction<ISourceJSON>) => {
					state.data = action.payload;
					state.status = "succeeded";
				}
			)
			.addCase(fetchSourceData.rejected, (state, action) => {
				state.status = "failed";
			});
	},
});

export default sourceSlice.reducer;
